import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { statusColors, statusLabel } from 'utils/constants';
import { formatDateTime } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    align: 'center',
  },
  {
    title: 'Available',
    dataIndex: 'is_available',
    align: 'center',
    render: value => (value ? 'Yes' : 'No'),
  },
  {
    title: 'List in General Library',
    dataIndex: 'is_general',
    align: 'center',
    render: value => {
      if (value) {
        return (
          <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
        );
      } else {
        return '-';
      }
    },
  },
  {
    title: 'List in Company Library',
    dataIndex: 'is_community',
    align: 'center',
    render: value => {
      if (value) {
        return (
          <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
        );
      } else {
        return '-';
      }
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/hub/courses/${record.id}/edit`}>Edit</Link>
        </Button>
        <Button shape="round">
          <Link to={`/hub/courses/${record.id}/chapters`}>All Chapters</Link>
        </Button>
      </Button.Group>
    ),
  },
];
