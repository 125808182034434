import {
  CheckCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useFetchApiList, usePostApi } from '@stagapps/redux-utils';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Empty,
  Image,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  cardTypeLabel,
  genderLabel,
  nationalityLabel,
} from 'apps/investment/constants/user';
import UIIconTooltip from 'common/ui/UIIconTooltip';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import companyApiCall from 'apps/investment/apiCalls/company';
import employeeApiCall from 'apps/investment/apiCalls/employee';
import listFundAccountApiCall from 'apps/investment/apiCalls/fundAccount/list';
import surveyApiCall from 'apps/investment/apiCalls/survey';
import apiCall from 'apps/investment/apiCalls/user';
import { identificationStatusColors } from 'apps/investment/constants/user';
import ErrorMessage from 'common/ui/ErrorMessage';
import {
  investmentIdentificationImageStatusColors,
  investmentIdentificationImageStatusLabel,
  investmentIdentificationImageTypeColors,
  investmentIdentificationImageTypeLabels,
} from 'utils/constants';
import { formatDate, formatDateTime } from 'utils/formatDate';
import { columnsFundAccount, columnsStockAccount } from '../config';

const { Item: DescItem } = Descriptions;
const { Title } = Typography;

const column = {
  xxl: 2,
  xl: 2,
  lg: 2,
  md: 1,
  sm: 1,
  xs: 1,
};

const ItemDetail = ({ item }) => {
  const {
    identification,
    code,
    email,
    id,
    name,
    email_verified,
    ekyc_verified,
    re_kyc,
    phone,
    phone_verified,
    updated_at,
    created_at,
  } = item || {};

  const {
    post: onApprove,
    isLoading: isApproving,
    error: approveError,
  } = usePostApi(apiCall.approve);

  const {
    post: onReject,
    isLoading: isRejecting,
    error: rejectError,
  } = usePostApi(apiCall.reject);

  const {
    post: resyncStatus,
    isLoading: isResynchronizing,
    error: resyncStatusError,
  } = usePostApi(apiCall.resyncStatus);

  const {
    post: createStockAccount,
    isLoading: isCreatingStockAccount,
    error: createStockAccountError,
  } = usePostApi(apiCall.createStockAccount);

  const {
    post: enableMutualFundAccount,
    isLoading: isEnableMutualFundAccount,
    error: enableMutualFundAccountError,
  } = usePostApi(apiCall.enableMutualFundAccount);

  const {
    post: createVcamB2BAccount,
    isLoading: isCreatingVcamB2BAccount,
    error: createVcamB2BAccountError,
  } = usePostApi(apiCall.createVcamB2BAccount);

  const {
    data: IDImage,
    load: loadIDImage,
    isLoading: isLoadingIDImage,
    error: loadIDImageError,
  } = useFetchApiList(apiCall.IDImage, { resourceName: 'data' });

  const {
    data: autoKyc,
    load: loadAutoKyc,
    isLoading: isLoadingAutoKyc,
    error: loadAutoKycError,
  } = useFetchApiList(apiCall.checkKyc, { resourceName: 'data' });

  const {
    data: requiredFundAccounts,
    load: loadRequiredFundAccounts,
    isLoading: isLoadingRequiredFundAccounts,
    error: loadRequiredFundAccountsError,
  } = useFetchApiList(employeeApiCall.requiredFundAccounts, {
    resourceName: 'data',
  });

  const {
    data: surveyHistories,
    load: loadSurveyHistories,
    isLoading: isLoadingsurveyHistories,
    error: loadsurveyHistoriesError,
  } = useFetchApiList(surveyApiCall.list, {
    resourceName: 'data',
  });

  const {
    data: userEmployees,
    load: loadUserEmployees,
    isLoading: isLoadingUserEmployees,
    error: loadUserEmployeesError,
  } = useFetchApiList(employeeApiCall.list, { resourceName: 'data' });

  const {
    data: userCompanies,
    load: loadUserCompanies,
    isLoading: isLoadingUserCompanies,
    error: loadUserCompaniesError,
  } = useFetchApiList(companyApiCall.list, { resourceName: 'data' });

  const {
    data: fundAccounts,
    load: loadFundAccounts,
    error: errorFundAccounts,
  } = useFetchApiList(listFundAccountApiCall, { resourceName: 'data' });

  const {
    data: stockAccount,
    load: loadStockAccount,
    error: errorStockAccount,
  } = useFetchApiList(apiCall.stockAccount, { resourceName: 'data' });

  useEffect(() => {
    if (id) {
      loadIDImage({ id });
      loadAutoKyc({ id });
      loadFundAccounts({ user_id: id });
      loadStockAccount({ id });
      loadRequiredFundAccounts({ id });
      loadUserEmployees({ user_id: id });
      loadUserCompanies({ user_id: id });
      loadSurveyHistories({ user_id: id });
    }
  }, [id]);

  const {
    full_name,
    id: ID_id,
    updated_at: ID_updated_at,
    created_at: ID_created_at,
  } = identification || {};

  let identificationLogs = item.identification_logs || [];
  identificationLogs.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  if (identification) {
    identification.identification_logs = identificationLogs;
  }

  return (
    <div className="flex flex-row gap-4 justify-between">
      <div className="flex flex-col gap-6 py-4 ">
        <div>
          {ekyc_verified ? (
            <Tag color="green">KYC đã xác thực</Tag>
          ) : (
            <Tag color="red">KYC chưa xác thực</Tag>
          )}
        </div>

        <Card
          title={<Title level={3}>Tài khoản</Title>}
          extra={
            <Button.Group>
              <Button type="primary">
                <Link to={`/investment/users/${item.id}/edit`}>Edit</Link>
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: 10,
                }}
              >
                <Link to={`/investment/portfolios?search=${item.email}`}>
                  View Portfolio
                </Link>
              </Button>
            </Button.Group>
          }
        >
          <Row>
            <Col>
              <Descriptions column={column} bordered>
                <DescItem label="Stag ID">{code}</DescItem>
                <DescItem label="Tên tài khoản">{name || full_name}</DescItem>
                <DescItem label="Email">
                  <Space align="center">
                    {email}
                    {email_verified ? <VerifiedTooltip /> : null}
                  </Space>
                </DescItem>
                <DescItem label="Số điện thoại">
                  <Space align="center">
                    {phone}
                    {phone_verified ? <VerifiedTooltip /> : null}
                  </Space>
                </DescItem>
                <DescItem label="Thời gian tạo">
                  {formatDateTime(created_at)}
                </DescItem>
                <DescItem label="Thời gian cập nhật">
                  {formatDateTime(updated_at)}
                </DescItem>
              </Descriptions>
            </Col>
          </Row>
        </Card>

        <Card
          title={<Title level={3}>Xác thực danh tính</Title>}
          extra={
            <Space align="center">
              {ID_id ? (
                <>
                  {re_kyc && ekyc_verified ? (
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: 'purple',
                        borderColor: 'purple',
                      }}
                    >
                      <Link
                        to={`/investment/users/${item.id}/identifications/${item.account_type}/${ID_id}/re-kyc`}
                      >
                        Review Re-KYC
                      </Link>
                    </Button>
                  ) : null}

                  <Button type="primary">
                    <Link
                      to={`/investment/users/${item.id}/identifications/${item.account_type}/${ID_id}/edit`}
                    >
                      Edit
                    </Link>
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: 'green',
                      borderColor: 'green',
                    }}
                  >
                    <Link
                      to={`/investment/users/${item.id}/identification-images`}
                    >
                      Image Detail
                    </Link>
                  </Button>
                </>
              ) : (
                <Button type="primary">
                  <Link
                    to={`/investment/users/${item.id}/identifications/${item.account_type}/create`}
                  >
                    Create
                  </Link>
                </Button>
              )}

              {!item.ekyc_verified && onApprove ? (
                <Button
                  disabled={isApproving}
                  onClick={() => {
                    let reason = prompt('Please enter note for approve EKYC:');
                    if (!reason) {
                      alert('Reason for approve EKYC is required');
                      return;
                    }
                    onApprove({ id: item.id, payload: { reason: reason } });
                  }}
                  type="primary"
                  style={{
                    backgroundColor: 'green',
                    borderColor: 'green',
                  }}
                >
                  Approve EKYC
                </Button>
              ) : null}

              {identification?.verification_status != 'approved' &&
              identification?.verification_status != 'rejected' &&
              onReject ? (
                <Button
                  disabled={isRejecting}
                  onClick={() => {
                    let reason = prompt(
                      'Please enter reason for rejecting EKYC:'
                    );
                    if (!reason) {
                      alert('Reason for rejecting EKYC is required');
                      return;
                    }
                    onReject({ id: item.id, payload: { reason: reason } });
                  }}
                  type="danger"
                  style={{
                    backgroundColor: 'red',
                    borderColor: 'red',
                    color: 'white',
                  }}
                >
                  Reject EKYC
                </Button>
              ) : null}
            </Space>
          }
        >
          <Row gutter={24}>
            <Col span={16}>
              {item.account_type == 'individual' ? (
                <IndividualIdentificationDetail
                  userIdentification={identification}
                  autoKyc={autoKyc}
                />
              ) : (
                <BusinessIdentificationDetail
                  userIdentification={identification}
                />
              )}
            </Col>
            <Col span={8}>
              <Card title="Auto Approver">
                {isLoadingAutoKyc ? (
                  <Spin />
                ) : loadAutoKycError ? (
                  <Tag color="red">
                    <ErrorMessage errors={loadAutoKycError} />
                  </Tag>
                ) : autoKyc.total_message > 0 ? (
                  <>
                    <Descriptions column={1} bordered>
                      <DescItem label="Kết luận eKYC">
                        <Tag color="red">
                          Phát hiện {autoKyc.total_message} bất thường
                        </Tag>
                        {autoKyc.field_warnings &&
                        'face_matching' in autoKyc.field_warnings ? (
                          <Tag color="red">
                            {autoKyc.field_warnings['face_matching']}
                          </Tag>
                        ) : null}
                      </DescItem>
                    </Descriptions>
                  </>
                ) : null}
              </Card>
              {isLoadingIDImage ? (
                <Spin />
              ) : loadIDImageError ? (
                <ErrorMessage errors={loadIDImageError} />
              ) : IDImage?.length > 0 ? (
                <>
                  {[...IDImage]
                    .reverse()
                    .map(
                      ({
                        id,
                        data,
                        image_url,
                        image_type,
                        status,
                        error_message,
                      }) => {
                        const imageBase64 = `data:image/jpeg;base64,${data}`;

                        return (
                          <Card key={id}>
                            <Descriptions column={1} bordered>
                              <DescItem label="Loại ảnh">
                                <Tag
                                  color={
                                    investmentIdentificationImageTypeColors[
                                      image_type
                                    ]
                                  }
                                >
                                  {
                                    investmentIdentificationImageTypeLabels[
                                      image_type
                                    ]
                                  }
                                </Tag>
                              </DescItem>
                              <DescItem label="Trạng thái xử lý ảnh">
                                <Tag
                                  color={
                                    investmentIdentificationImageStatusColors[
                                      status
                                    ]
                                  }
                                >
                                  {
                                    investmentIdentificationImageStatusLabel[
                                      status
                                    ]
                                  }
                                </Tag>
                              </DescItem>
                              <DescItem label="Ảnh">
                                <Image
                                  style={{ maxHeight: 666 }}
                                  src={image_url || imageBase64}
                                />
                              </DescItem>
                              {error_message !== '' && (
                                <DescItem label="Thông báo eKYC">
                                  <Tag color="purple">{error_message}</Tag>
                                </DescItem>
                              )}
                            </Descriptions>
                          </Card>
                        );
                      }
                    )}
                </>
              ) : null}
            </Col>
          </Row>
        </Card>

        {item.account_type == 'individual' ? (
          <Card title={<Title level={3}>Nhân viên</Title>}>
            {userEmployees && userEmployees.length > 0 ? (
              <Descriptions column={1} bordered>
                {userEmployees.map(userEmployee => (
                  <DescItem
                    label={
                      userEmployee.full_name
                        ? userEmployee.full_name
                        : userEmployee.email
                    }
                  >
                    <Link to={`/investment/employees/${userEmployee.id}`}>
                      Xem chi tiết #{userEmployee.id}
                    </Link>
                  </DescItem>
                ))}
              </Descriptions>
            ) : (
              <Empty description="Chưa tham gia chương trình FutureFlex dành cho nhân viên" />
            )}
          </Card>
        ) : (
          <Card title={<Title level={3}>Doanh nghiệp</Title>}>
            {userCompanies && userCompanies.length > 0 ? (
              <Descriptions column={2} bordered>
                {userCompanies.map(userCompany => (
                  <>
                    <DescItem label={userCompany.name}>
                      <Link to={`/investment/companies/${userCompany.id}`}>
                        Xem chi tiết #{userCompany.id}
                      </Link>
                    </DescItem>
                    <DescItem label={'HR Admin'}>
                      <Link
                        to={`/investment/staffs?company_id=${userCompany.id}`}
                      >
                        Xem danh sách #{userCompany.id}
                      </Link>
                    </DescItem>
                  </>
                ))}
              </Descriptions>
            ) : (
              <Empty description="Chưa tham gia chương trình FutureFlex dành cho doanh nghiệp" />
            )}
          </Card>
        )}

        {item.account_type == 'individual' &&
        userEmployees &&
        userEmployees.length > 0 ? (
          <Card title={<Title level={3}>Tài khoản Quỹ cần mở</Title>}>
            {requiredFundAccounts && requiredFundAccounts.length > 0 ? (
              <Descriptions column={1} bordered>
                {requiredFundAccounts.map(requiredFundAccount => (
                  <DescItem
                    label={`${
                      requiredFundAccount.fund_manager_name
                    } (${requiredFundAccount.fund_manager.toUpperCase()})`}
                  >
                    {requiredFundAccount.is_approved ? (
                      <Tooltip title="Đã duyệt">
                        <CheckCircleOutlined
                          style={{ color: 'green', fontSize: '20px' }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Chưa tạo hoặc chưa duyệt">
                        <InfoCircleOutlined
                          style={{ color: '#b8860b', fontSize: '20px' }}
                        />
                      </Tooltip>
                    )}
                  </DescItem>
                ))}
              </Descriptions>
            ) : (
              <Empty description="Không có tài khoản quỹ cần mở" />
            )}
          </Card>
        ) : null}

        <Card title={<Title level={3}>Tài khoản Quỹ</Title>}>
          {!!errorFundAccounts ? (
            <ErrorMessage errors={errorFundAccounts} />
          ) : (
            <Table
              dataSource={fundAccounts}
              columns={columnsFundAccount({
                userID: item.id,
                createVcamB2BAccount: createVcamB2BAccount,
                isCreatingVcamB2BAccount: isCreatingVcamB2BAccount,
                resyncStatus: resyncStatus,
                isResynchronizing: isResynchronizing,
              })}
            />
          )}
        </Card>

        <Card title={<Title level={3}>Tài khoản Chứng khoán</Title>}>
          {!!errorStockAccount ? (
            <ErrorMessage errors={errorStockAccount} />
          ) : (
            <Table
              dataSource={stockAccount && stockAccount.id ? [stockAccount] : []}
              columns={columnsStockAccount({
                userID: item.id,
                createStockAccount: createStockAccount,
                isCreatingStockAccount: isCreatingStockAccount,
                enableMutualFundAccount: enableMutualFundAccount,
                isEnableMutualFundAccount: isEnableMutualFundAccount,
              })}
              locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
              }}
            />
          )}
        </Card>

        <Card title={<Title level={3}>Khảo sát</Title>}>
          <Row gutter={24}>
            <Col span={16}>
              <a
                href={`/investment/user-survey-histories?user_id=${item.id}`}
                target="_blank"
              >
                Xem chi tiết
              </a>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default ItemDetail;

const IndividualIdentificationDetail = ({ userIdentification, autoKyc }) => {
  if (!userIdentification)
    return <Empty description="Chưa có thông tin xác thực" />;

  var fieldWarnings = {};
  if (autoKyc) {
    fieldWarnings = autoKyc.field_warnings;
  }

  return (
    <div className="flex flex-col gap-6 ">
      <Descriptions column={1} bordered>
        <DescItem label="Quốc tịch">
          {nationalityLabel[userIdentification.nationality] ?? '-'}
          {fieldWarnings && 'nationality' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['nationality']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Họ và tên">
          {userIdentification.full_name}{' '}
          {fieldWarnings && 'full_name' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['full_name']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Ngày sinh">
          {formatDate(userIdentification.date_of_birth)}{' '}
          {fieldWarnings && 'date_of_birth' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['date_of_birth']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Giới tính">
          {genderLabel[userIdentification.gender] ?? '-'}
          {fieldWarnings && 'gender' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['gender']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Loại căn cước">
          {cardTypeLabel[userIdentification.card_type]}
        </DescItem>
        <DescItem label="Số CMND/CCCD">
          {userIdentification.id_number}{' '}
          {fieldWarnings && 'id_number' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['id_number']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Ngày cấp">
          {formatDate(userIdentification.issue_date)}
          {fieldWarnings && 'issue_date' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['issue_date']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Ngày hết hạn">
          {formatDate(userIdentification.expiry)}
          {fieldWarnings && 'expiry' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['expiry']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Nơi cấp">
          {userIdentification.issue_place}{' '}
          {fieldWarnings && 'issue_place' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['issue_place']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Quê quán">
          {userIdentification.original_address}
          {fieldWarnings && 'original_address' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['original_address']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Trạng thái xác thực">
          <Tag
            color={
              identificationStatusColors[userIdentification.verification_status]
            }
          >
            {userIdentification.verification_status_label}
          </Tag>
        </DescItem>
        <DescItem label="Ghi chú">
          {userIdentification?.identification_logs?.length > 0
            ? userIdentification.identification_logs.map((log, index) => (
                <p key={index}>
                  <Tag color="green">{log.admin_email ?? 'Admin'}</Tag> chuyển
                  sang &nbsp;
                  <Tag
                    color={identificationStatusColors[log.verification_status]}
                  >
                    {log.verification_status_label}
                  </Tag>
                  (Ghi chú:{' '}
                  <UIIconTooltip
                    size="small"
                    title="Edit"
                    Icon={EditOutlined}
                  />{' '}
                  &nbsp;
                  <Tag color="grey">{log.note}</Tag>
                  {formatDateTime(log.created_at)})
                </p>
              ))
            : '-'}
        </DescItem>
      </Descriptions>
      <Descriptions title="Địa chỉ thường trú" column={1} bordered>
        <DescItem label="Thành phố/Tỉnh">
          {userIdentification.residential_address_city ? (
            <Link
              to={`/investment/provinces/${userIdentification.residential_address_city.id}`}
            >
              {userIdentification.residential_address_city
                ? userIdentification.residential_address_city.name
                : userIdentification.residential_address_city.id}
            </Link>
          ) : userIdentification.residential_address_city_string ? (
            userIdentification.residential_address_city_string
          ) : (
            '-'
          )}
        </DescItem>
        <DescItem label="Quận/Huyện">
          {userIdentification.residential_address_district ? (
            <Link
              to={`/investment/districts/${userIdentification.residential_address_district.id}`}
            >
              {userIdentification.residential_address_district
                ? userIdentification.residential_address_district.name
                : '-'}
            </Link>
          ) : userIdentification.residential_address_district_string ? (
            userIdentification.residential_address_district_string
          ) : (
            '-'
          )}
        </DescItem>
        <DescItem label="Địa chỉ">
          {userIdentification.residential_address}
          {fieldWarnings && 'residential_address' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['residential_address']}</Tag>
          ) : null}
        </DescItem>
      </Descriptions>

      <Descriptions title={'Địa chỉ liên hệ'} column={1} bordered>
        <DescItem label="Thành phố/Tỉnh">
          {userIdentification.address_city ? (
            <Link
              to={`/investment/provinces/${userIdentification.address_city.id}`}
            >
              {userIdentification.address_city.name
                ? userIdentification.address_city.name
                : userIdentification.address_city.id}
            </Link>
          ) : userIdentification.address_city_string ? (
            userIdentification.address_city_string
          ) : (
            '-'
          )}
        </DescItem>
        <DescItem label="Quận/Huyện">
          {userIdentification.address_district ? (
            <Link
              to={`/investment/districts/${userIdentification.address_district.id}`}
            >
              {userIdentification.address_district_string
                ? userIdentification.address_district_string
                : userIdentification.address_district.id}
            </Link>
          ) : userIdentification.address_district_string ? (
            userIdentification.address_district_string
          ) : (
            '-'
          )}
        </DescItem>
        <DescItem label="Địa chỉ">
          {userIdentification.address}
          {fieldWarnings && 'address' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['address']}</Tag>
          ) : null}
        </DescItem>
      </Descriptions>

      <Descriptions title={'Thông tin ngân hàng'} column={1} bordered>
        <DescItem label="Tên ngân hàng">
          {userIdentification.bank ? (
            <Link to={`/investment/banks/${userIdentification.bank.id}`}>
              {`${userIdentification.bank_name} (${userIdentification.bank.code})`}
            </Link>
          ) : userIdentification.bank_name ? (
            userIdentification.bank_name
          ) : (
            '-'
          )}
        </DescItem>
        <DescItem label="Chủ tài khoản">
          {userIdentification.bank_account_name}
          {fieldWarnings && 'bank_account_name' in fieldWarnings ? (
            <Tag color="red">{fieldWarnings['bank_account_name']}</Tag>
          ) : null}
        </DescItem>
        <DescItem label="Số tài khoản">
          {userIdentification.bank_account_number}
        </DescItem>
        <DescItem label="Chi nhánh">
          {userIdentification.bank_branch_name}
        </DescItem>
      </Descriptions>
    </div>
  );
};

const BusinessIdentificationDetail = ({ userIdentification }) => {
  if (!userIdentification)
    return <Empty description="Chưa có thông tin xác thực" />;

  return (
    <div className="flex flex-col gap-6 ">
      <Descriptions column={1} bordered>
        <DescItem label="Tên doanh nghiệp">
          {userIdentification.full_name}
        </DescItem>
        <DescItem label="Ngày đăng ký lần đầu">
          {userIdentification.date_of_birth}
        </DescItem>
        <DescItem label="Loại căn cước">
          {cardTypeLabel[userIdentification.card_type]}
        </DescItem>
        <DescItem label="Số chứng nhận đăng ký doanh nghiệp / Mã số thuế">
          {userIdentification.id_number}
        </DescItem>
        <DescItem label="Nơi cấp">{userIdentification.issue_place}</DescItem>
        <DescItem label="Trạng thái xác thực">
          <Tag
            color={
              identificationStatusColors[userIdentification.verification_status]
            }
          >
            {userIdentification.verification_status_label}
          </Tag>
        </DescItem>
        <DescItem label="Ghi chú">
          {userIdentification?.identification_logs?.length > 0
            ? userIdentification.identification_logs.map((log, index) => (
                <p key={index}>
                  <Tag color="green">{log.admin_email ?? 'Admin'}</Tag> chuyển
                  sang &nbsp;
                  <Tag
                    color={identificationStatusColors[log.verification_status]}
                  >
                    {log.verification_status_label}
                  </Tag>
                  (Ghi chú:{' '}
                  <UIIconTooltip
                    size="small"
                    title="Edit"
                    Icon={EditOutlined}
                  />{' '}
                  &nbsp;
                  <Tag color="grey">{log.note}</Tag>
                  {formatDateTime(log.created_at)})
                </p>
              ))
            : '-'}
        </DescItem>
      </Descriptions>
      <Descriptions title="Địa chỉ đăng ký kinh doanh" column={1} bordered>
        <DescItem label="Thành phố/Tỉnh">
          {userIdentification.residential_address_city ? (
            <Link
              to={`/investment/provinces/${userIdentification.residential_address_city.id}`}
            >
              {userIdentification.residential_address_city
                ? userIdentification.residential_address_city.name
                : userIdentification.residential_address_city.id}
            </Link>
          ) : userIdentification.residential_address_city_string ? (
            userIdentification.residential_address_city_string
          ) : (
            '-'
          )}
        </DescItem>
        <DescItem label="Quận/Huyện">
          {userIdentification.residential_address_district ? (
            <Link
              to={`/investment/districts/${userIdentification.residential_address_district.id}`}
            >
              {userIdentification.residential_address_district
                ? userIdentification.residential_address_district.name
                : '-'}
            </Link>
          ) : userIdentification.residential_address_district_string ? (
            userIdentification.residential_address_district_string
          ) : (
            '-'
          )}
        </DescItem>
        <DescItem label="Địa chỉ">
          {userIdentification.residential_address}
        </DescItem>
      </Descriptions>

      <Descriptions title={'Địa chỉ liên hệ'} column={1} bordered>
        <DescItem label="Thành phố/Tỉnh">
          {userIdentification.address_city ? (
            <Link
              to={`/investment/provinces/${userIdentification.address_city.id}`}
            >
              {userIdentification.address_city.name
                ? userIdentification.address_city.name
                : userIdentification.address_city.id}
            </Link>
          ) : userIdentification.address_city_string ? (
            userIdentification.address_city_string
          ) : (
            '-'
          )}
        </DescItem>
        <DescItem label="Quận/Huyện">
          {userIdentification.address_district ? (
            <Link
              to={`/investment/districts/${userIdentification.address_district.id}`}
            >
              {userIdentification.address_district_string
                ? userIdentification.address_district_string
                : userIdentification.address_district.id}
            </Link>
          ) : userIdentification.address_district_string ? (
            userIdentification.address_district_string
          ) : (
            '-'
          )}
        </DescItem>
        <DescItem label="Địa chỉ">{userIdentification.address}</DescItem>
      </Descriptions>
    </div>
  );
};

const VerifiedTooltip = () => (
  <Tooltip title="Đã xác thực">
    <CheckCircleOutlined style={{ color: 'green', fontSize: '1.125rem' }} />
  </Tooltip>
);
