import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Input, Select } from 'antd';

import UIDatePicker from 'common/ui/UIDatePicker';
import apiBankCall from 'apps/investment/apiCalls/data/banks';
import apiDistrictsCall from 'apps/investment/apiCalls/data/districts';
import apiProvincesCall from 'apps/investment/apiCalls/data/provinces';
import {
  cardTypeOptions,
  genderOptions,
  nationalityOptions,
} from 'apps/investment/constants/user';
import DebounceSelect from 'common/ui/DebouceSelect';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const { Option } = Select;

const dateFields = ['date_of_birth', 'issue_date', 'expiry'];

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
  params,
}) => {
  const {
    address_district_id,
    address_district_string,
    residential_address_district_id,
    residential_address_district_string,
    bank_id,
    bank_name,
  } = initialValues;

  const { residential_address_city_id, address_city_id } =
    Form.useWatch([], form) || {};

  useEffect(() => {
    initialValues.user_id = params.userId;
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  const { data: banks, load: loadBank } =
    useFetchApiList(apiBankCall, { resourceName: 'data' }) || {};
  const {
    data: provinces,
    isLoading: isProvincesLoading,
    load: loadProvinces,
  } = useFetchApiList(apiProvincesCall, { resourceName: 'data' }) || {};

  const { data: residentialDistricts, load: loadResidentialDistricts } =
    useFetchApiList(apiDistrictsCall('residential_address'), {
      resourceName: 'data',
    }) || {};

  const { data: districts, load: loadDistricts } =
    useFetchApiList(apiDistrictsCall('address'), {
      resourceName: 'data',
    }) || {};

  useEffect(() => {
    loadBank();
    loadProvinces();
  }, []);

  useEffect(() => {
    if (
      residential_address_city_id !== null &&
      residential_address_city_id !== undefined
    ) {
      loadResidentialDistricts({ province_id: residential_address_city_id });
    }
  }, [residential_address_city_id]);

  useEffect(() => {
    if (address_city_id !== null && address_city_id !== undefined) {
      loadDistricts({ province_id: address_city_id });
    }
  }, [address_city_id]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_form'}
      onFinish={values =>
        onFinish({ ...convertDateFormData(values, dateFields) })
      }
      scrollToFirstError
    >
      <Form.Item name="user_id" label="Mã người dùng">
        <Input placeholder="" readOnly disabled />
      </Form.Item>

      <Form.Item
        name="card_type"
        label="Loại giấy tờ định danh"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Select>
          {cardTypeOptions &&
            cardTypeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="nationality"
        label="Quốc tịch"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Select>
          {nationalityOptions &&
            nationalityOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="id_number"
        label="Số định danh cá nhân"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="full_name"
        label="Full Name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="date_of_birth" label="Ngày tháng năm sinh">
        <UIDatePicker placeholder="" />
      </Form.Item>

      <Form.Item name="gender" label="Giới tính">
        <Select>
          {genderOptions &&
            genderOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="issue_date" label="Ngày cấp">
        <UIDatePicker placeholder="" />
      </Form.Item>

      <Form.Item name="expiry" label="Ngày hết hạn">
        <UIDatePicker placeholder="" />
      </Form.Item>

      <Form.Item name="issue_place" label="Nơi cấp">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="original_address" label="Quê quán">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="residential_address" label="Địa chỉ thường trú">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="residential_address_city_id"
        label="Địa chỉ thường trú - Tỉnh/Thành phố"
      >
        <DebounceSelect
          placeholder="Select or search"
          fetchOptions={loadProvinces}
          fetching={isProvincesLoading}
          options={provinces}
          displayFn={o => {
            return {
              value: o.id,
              label: o.name,
            };
          }}
        />
      </Form.Item>

      <Form.Item
        name="residential_address_district_id"
        label="Địa chỉ thường trú - Quận/Huyện"
      >
        <Select
          defaultValue={{
            value: residential_address_district_id,
            label: residential_address_district_string,
          }}
          placeholder="Select a option and change input text above"
          allowClear
        >
          {residentialDistricts.map(({ id, name }) => (
            <Option value={id}>{name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="residential_address_ward"
        label="Địa chỉ thường trú - Phường/Xã"
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="address" label="Địa chỉ liên hệ">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="address_city_id"
        label="Địa chỉ liên hệ - Tỉnh/Thành phố"
      >
        <DebounceSelect
          placeholder="Select or search"
          fetchOptions={loadProvinces}
          fetching={isProvincesLoading}
          options={provinces}
          displayFn={o => {
            return {
              value: o.id,
              label: o.name,
            };
          }}
        />
      </Form.Item>

      <Form.Item
        name="address_district_id"
        label="Địa chỉ liên hệ - Quận/Huyện"
      >
        <Select
          placeholder="Select a option and change input text above"
          allowClear
          defaultValue={{
            value: address_district_id,
            label: address_district_string,
          }}
        >
          {districts?.map(({ id, name }) => (
            <Option value={id}>{name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="address_ward" label="Địa chỉ liên hệ - Phường/Xã">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="bank_id" label="Ngân hàng">
        <Select
          placeholder="Select a option and change input text above"
          allowClear
          defaultValue={{
            value: bank_id,
            label: bank_name,
          }}
        >
          {banks?.map(({ id, name }) => (
            <Option value={id}>{name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="bank_account_number" label="STK Ngân hàng">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="bank_account_name" label="Tên tài khoản ngân hàng">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="bank_branch_name" label="Chi nhánh ngân hàng">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
